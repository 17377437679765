import Cookies from "universal-cookie";

class SessionStore {
  constructor() {
    this.cookies = new Cookies();
  }

  /*
    @params
    [name (string)] cookie name
    [data (anything)]
    [expires (Date)] (optional) see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Date
  */
  set(name, data, expires = null) {
    let options = { path: "/" };
    if (!!expires) {
      options.expires = expires;
    }
    this.cookies.set(name, JSON.stringify(data), options);
  }

  setString(name, string) {
    this.cookies.set(name, string, { path: "/" });
  }

  get(name) {
    return this.cookies.get(name, {});
  }

  remove(name) {
    //this.cookies.remove(name, {});
    try {
      this.cookies.remove(name, { path: "/" });
    } catch (e) {
      // cookie potentially doesn't exist
    }
  }
}

export default new SessionStore();
