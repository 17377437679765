import permissions from "patient_app/helpers/permissions";

/* params:
 *   copay (integer): copay amount in cents
 *   program (string): one of "oud", "aud", or "counselor"
 *
 * returns cost of program as a string
 */
export function formatProgramCost(copay, program, state) {
  let formattedCost = "";
  const isInState = permissions.isInState({ state: state });

  if (copay && copay > 0) {
    formattedCost = `$${(copay / 100).toFixed(2)}/week`;
  } else {
    // guess what tier user might be in
    if (program === "oud") {
      formattedCost = `$75.00/week`;
    } else if (program === "aud") {
      formattedCost = `$300.00/month`;
    } else if (program === "counselor" && isInState) {
      formattedCost = `$50.00/week`;
    } else if (program === "counselor" && !isInState) {
      formattedCost = `$25.00/week`;
    }
  }

  return formattedCost;
}

export function insurances() {
  return [
    { title: "Aetna" },
    { title: "Alliance Health", state: "MI" },
    { title: "Anthem Blue Cross", state: "CA" },
    {
      title: "BCBS - Traditional",
      alts: ["blue cross blue shield traditional"],
      state: "MI",
    },
    {
      title: "BCBS Blue Preferred Plus",
      alts: ["blue cross blue shield preferred plus"],
      state: "MI",
    },
    { title: "Blue Care Network", state: "MI" },
    { title: "Blue Cross Complete", state: "MI" },
    { title: "Blue Shield of California", state: "CA" },
    { title: "Cigna", state: "CA" },
    { title: "Health Alliance Plan", state: "MI" },
    { title: "Health Care Service Corporation", state: "MI" },
    { title: "HealthNet", state: "CA" },
    { title: "Humana" },
    { title: "Kaiser", state: "CA" },
    { title: "McLaren" },
    { title: "Medicaid", value: "Medicaid" },
    //{ title: 'Medicare', value: "medicare" },
    { title: "Medi-Cal", value: "Medicaid", state: "CA" },
    { title: "Meridian", state: "MI" },
    { title: "Molina", state: "MI" },
    { title: "Premera" },
    { title: "Lifewise" },
    { title: "Physician’s Health Plan", state: "MI" },
    { title: "Priority Health", state: "MI" },
    { title: "Total Health Care", state: "MI" },
    { title: "Tricare West", state: "CA" },
    { title: "United Healthcare", state: "CA" },
    //nj
    { title: "AmeriHealth", state: "NJ" },
    { title: "Assurant Health Health Insurance", state: "NJ" },
    {
      title: "Horizon BCBS of NJ Health Insurance",
      alts: ["Horizon Blue Cross Blue Shield of New Jersey Health Insurance"],
      state: "NJ",
    },
    { title: "Oxford New Jersey Health Insurance", state: "NJ" },
    { title: "No Insurance" },
    { title: "Other", value: "other" },
  ];
}

export function getInsuranceLogo(planName) {
  const formatted = planName && planName.toLowerCase();
  let url;

  if (formatted.includes("aetna")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Aetna.svg";
  } else if (formatted.includes("medicare")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Medicare.svg";
  } else if (formatted.includes("medicaid")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Medicaid.svg";
  } else if (formatted.includes("medi-cal")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/logos/insurers/blue/Medi-Cal_Blue.svg";
  } else if (formatted.includes("priority health")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Priority.svg";
  } else if (formatted.includes("horizon")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Horizon.svg";
  } else if (formatted.includes("meridian")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/Meridian.svg";
  } else if (formatted.includes("mclaren")) {
    url =
      "https://storage.googleapis.com/workit-client-assets/onboarding/insurance_company_logos/McLaren.svg";
  }

  return url;
}

export function getPlanTypes(stateCoverages, insurer) {
  if (!insurer) {
    return [];
  }

  if (insurer && stateCoverages) {
    const coverages = stateCoverages.filter((sc) => {
      return sc.insurance_provider_id === insurer.id && !!sc.line_of_business;
    });

    let planTypes = coverages.map((sc) => {
      return formatLineOfBusiness(sc.line_of_business);
    });

    planTypes = _.uniq(planTypes);
    planTypes = _.without(planTypes, undefined);

    return planTypes;
  }

  return [];
}

export function formatLineOfBusiness(lob) {
  if (lob === "commercial") {
    return "Commercial";
  } else if (lob === "medicaid") {
    return "Medicaid/Medi-Cal";
  } else if (lob === "medicare_advantage") {
    return "Medicare Advantage";
  }
}

export function checkInitialCoverage(
  coverageInfo,
  clinicPrograms,
  insuranceProviders,
  stateCoverages
) {
  const insurer = insuranceProviders.find(
    (ip) => ip.id === coverageInfo.insuranceProviderId
  );

  let clientCoverage = {
    coverage_verified: false,
    covered_status: "NOT_COVERED",
    custom_error: "",
    error: "",
    group_id: "",
    insurance_provider_id: coverageInfo.insuranceProviderId,
    json: "",
    member_id: coverageInfo.memberId,
    plan_type: coverageInfo.planType,
    reason: "Not Covered",
    status: "NOT_COVERED",
    success: true,
  };

  const clinicProgramIds =
    !!coverageInfo.program && clinicPrograms
      ? _.map(
          clinicPrograms.filter((cp) =>
            coverageInfo.program.split(",").includes(cp.uid)
          ),
          "id"
        )
      : [];

  const coverages =
    stateCoverages?.filter(
      (cov) =>
        cov.insurance_provider_id === coverageInfo.insuranceProviderId &&
        cov.state === coverageInfo.state &&
        clinicProgramIds.includes(cov.clinic_program_id)
    ) || [];

  let coverage;
  if (coverages.length === 0) {
    return { preCheck: false, clientResults: clientCoverage };
  } else if (coverages.length === 1) {
    coverage = coverages[0];
  } else {
    let lob = "";
    switch (coverageInfo.planType) {
      case "Commercial":
        lob = "commercial";
        break;
      case "Medicaid/Medi-Cal":
        lob = "medicaid";
        break;
      case "Medicare Advantage":
        lob = "medicare_advantage";
        break;
      default:
        break;
    }

    coverage = lob && coverages.find((cov) => cov.line_of_business === lob);
  }

  if (!coverage || !coverage.allows_ssis || !coverageInfo.program) {
    return { preCheck: false, clientResults: clientCoverage };
  }

  return { preCheck: true, clientResults: null };
}

export function relationshipToPolicyholderOptions() {
  return [
    ["I am the policyholder", "primary"],
    ["I am the spouse of the policyholder", "spouse"],
    ["I am a dependent on the policy", "dependent"],
  ];
}
