import _ from "lodash";

let permissions = {
  isClinic(user) {
    //return (admin && admin.roles && _.find(admin.roles, {name: "COACH"}))
    return parseInt(user?.tier) === 3;
  },
  isAud(user, primaryDiagnosis) {
    const treatmentType = primaryDiagnosis?.uid || user?.treatment_type;
    return parseInt(user?.tier) === 3 && treatmentType?.includes("aud");
  },
  isOud(user, primaryDiagnosis) {
    const treatmentType = primaryDiagnosis?.uid || user?.treatment_type;
    return parseInt(user?.tier) === 3 && treatmentType?.includes("oud");
  },
  isCore(user) {
    return parseInt(user?.tier) === 6;
  },
  isCounselor(user) {
    return parseInt(user?.tier) === 2;
  },
  isCoach(user) {
    return parseInt(user?.tier) === 1;
  },
  isEnterprise(profile) {
    return profile?.billing_type === "enterprise";
  },
  canBook(user) {
    return user?.tier > 1 && !permissions.isCore(user);
  },
  isInState(profile) {
    const states = [
      "AK",
      "AL",
      "AZ",
      "CO",
      "MD",
      "FL",
      "MA",
      "GA",
      "IA",
      "IL",
      "LA",
      "MN",
      "NM",
      "MO",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "TX",
      "VA",
      "WA",
      "CA",
      "MI",
      "NJ",
    ];
    return states.includes(profile?.state);
  },
  isInClinicState(profile, clinics) {
    const states = _.map(clinics, "state");
    return states.includes(profile?.state);
  },
  isInClinicStateWithCounseling(clinic) {
    let hasCounseling = clinic?.counseling_active || false;
    return hasCounseling;
  },
};

export default permissions;
